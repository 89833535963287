import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

import Slide1 from 'src/slides/desktop/ua/chapter_5/sub_1/slide1';
import WrapperMobile from 'src/slides/mobile/ua/chapter_5/sub_1/wrapperMobile';

import { isMobile } from 'react-device-detect';
import SEO from 'src/components/_shared/seo';


const allSlides = [<Slide1 />];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Едгар Вінсент Д'Абернон I Варшавська битва" description="Хто та людина, яка назвала Варшавську битву 18-м вирішальним боєм в історії." lang="ua" />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO title="Едгар Вінсент Д'Абернон I Варшавська битва" description="Хто та людина, яка назвала Варшавську битву 18-м вирішальним боєм в історії." lang="ua" />
    <WrapperMobile />
  </>
);

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
